import React from 'react';
import Layout from '../components/shared/Layout';
import SEO from '../components/shared/SEO';
import Footer from "../components/shared/Footer/Footer";
import {getRoutes} from '../components/shared/routes';
import Forest from "../components/sections/Forest/Forest";
import Logistic from "../components/sections/logistic/Logistic";
import AdditionalInformation
    from "../components/shared/additionalInformation/additionalInformation";
import PageTitle from "../components/shared/PageTitle/PageTitle";
import SmallFlowDiagram from "../components/sections/SmallFlowDiagram/SmallFlowDiagram";
import ForestStreet from "../components/sections/ForestStreet/ForestStreet";
import SawmillTrader from "../components/sections/sawmillTrader/SawmillTrader";
import AtTheSawmill from "../components/sections/AtTheSawmill/AtTheSawmill";

const Traceability = () => {
    const routes = getRoutes().Traceability;
    const pageTitle = routes.pageTitle;

    return (
        <Layout>
            <SEO title={pageTitle}/>

            <PageTitle>{routes.pageTitle}</PageTitle>

            <SmallFlowDiagram/>

            <div id={routes.sections.forest.anchor}>
                <Forest/>
            </div>

            <div id={routes.sections.forestStreet.anchor}>
                <ForestStreet/>
            </div>

            <div id={routes.sections.logistic.anchor}>
                <Logistic/>
            </div>

            <div id={routes.sections.sawmillTrader.anchor}>
                <SawmillTrader/>
            </div>

            <div id={routes.sections.atTheSawmill.anchor}>
                <AtTheSawmill/>
            </div>
            {/*<Measurement/>*/}

            {/*<Marking/>*/}

            {/*<Inventory/>*/}

            <AdditionalInformation/>

            <Footer/>

        </Layout>
    );
};

export default Traceability;
